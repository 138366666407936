import React from "react";
import { Card, Media } from "react-bootstrap";
import { IProduct } from "../../../common/interfaces/records";
import { Link } from "react-router-dom";
import { backend } from "../index";

export class Collection1 extends React.Component<{match: {params: {number: string;};};}, {products: IProduct[]}>
{
	constructor(props: any)
	{
		super(props);
		this.state = {products: []};
		this.loadData().catch();
	}

	render()
	{
		return (
			<div>
				<h2>Products</h2>
				<Card body>
					{this.state.products.map(item => (<Media>
							{typeof item.pictures !== 'undefined' ? <img
								width={64}
								height={64}
								className="mr-3"
								src={item.pictures[0].src}
								key={item.id}
								alt={item.name}/> : ''}
							<Media.Body>
								<h5 key={item.id}><Link to={`/product/${item.id}`}>{item.name}</Link></h5>
								<p>
									This is just description. Cras sit amet nibh libero, in gravida nulla vel
									metus scelerisque ante sollicit ommodo.Cras purus odio, vestibulum in vulputate at,
									tempus viverra turpis.Fusce condimentum nunc ac nisi vulputate fringilla.
									Donec lacinia congue felis in faucibus.
								</p>
							</Media.Body>
						</Media>
					))}
				</Card>
			</div>
		)
	}

	async loadData()
	{
		let products = await backend.getProducts(this.props.match.params.number)
		this.setState(_ => ({
			products: products
		}));
	}
}