import { cloneElement } from "react";
import * as React from "react";
import {
	List,
	Datagrid,
	TextField,
	ImageField,
	TopToolbar,
	CreateButton,
	ExportButton,
	EditButton,
	sanitizeListRestProps,
	Create,
	Edit,
	SimpleForm,
	TextInput,
	ImageInput,
	ReferenceArrayInput,
	SelectArrayInput,
	ReferenceArrayField,
	SingleFieldList,
	ChipField
} from 'react-admin';

const CollectionActions = ({
	                     currentSort,
	                     className,
	                     resource,
	                     filters,
	                     displayedFilters,
	                     exporter, // you can hide ExportButton if exporter = (null || false)
	                     filterValues,
	                     permanentFilter,
	                     hasCreate, // you can hide CreateButton if hasCreate = false
	                     basePath,
	                     selectedIds,
	                     onUnselectItems,
	                     showFilter,
	                     maxResults,
	                     total,
	                     ...rest
                     }: {[_: string]: any}) => (
	<TopToolbar className={className} {...sanitizeListRestProps(rest)}>
		{filters && cloneElement(filters, {
			resource,
			showFilter,
			displayedFilters,
			filterValues,
			context: 'button',
		})}
		<CreateButton basePath={basePath}/>
		<ExportButton
			disabled={total === 0}
			resource={resource}
			sort={currentSort}
			filter={{...filterValues, ...permanentFilter}}
			exporter={exporter}
			maxResults={maxResults}
		/>
	</TopToolbar>
);

CollectionActions.defaultProps = {
	selectedIds: [],
	onUnselectItems: () => null,
};

export const Collection = ({permissions, ...props}: any) => (
	<List {...props} actions={<CollectionActions permissions={permissions} {...props} />}>
		<Datagrid>
			<TextField source="name" />
			<EditButton/>
		</Datagrid>
	</List>
);

export const CollectionCreate = (props: any) => (
	<Create {...props}>
		<SimpleForm>
			<TextInput source="name" />
		</SimpleForm>
	</Create>
);

export const CollectionEdit = (props: any) => (
	<Edit  {...props}>
		<SimpleForm>
			<TextInput source="name" />
		</SimpleForm>
	</Edit>
);
