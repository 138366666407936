import { IApiConf, ITodoItem, IProduct, ICollections } from "../../../common/interfaces/records";

const HEADERS = {
	'Accept': 'application/json',
	'Content-Type': 'application/json',
	'Connection': 'close',
};

export class BackendService
{
	private _timeDifference = 0;
	private _tag = "[backend]";
	private _logRequest: boolean = true;
	private _logResponse: boolean = false;

	async getConf(): Promise<IApiConf>
	{
		let conf = await this.call<IApiConf>("conf");
		this._timeDifference = Math.abs(conf.time - Date.now() / 1000);

		if (this._logRequest)
			console.log(this._tag, "-> frontend time difference:", this._timeDifference);

		return conf;
	}

	async getItems(): Promise<ITodoItem[]>
	{
		return this.call<ITodoItem[]>("items");
	}

	async getCollections(): Promise<ICollections[]>
	{
		return this.call<ICollections[]>("collections");
	}

	async getProducts(id: string): Promise<IProduct[]>
	{
		return this.call<IProduct[]>(`products/${id}`);
	}

	async getProduct(id: string): Promise<IProduct>
	{
		return this.call<IProduct>(`product/${id}`);
	}

	async getSpin(id: string): Promise<IProduct>
	{
		return this.call<IProduct>(`spin/${id}.spin`);
	}

	async post<T>(item: T)
	{
		return this.call<boolean>("item", item, "POST");
	}

	private async call<T>(path: string, params: unknown = {}, method: string = "GET"): Promise<T>
	{
		let url = `${process.env.REACT_APP_API_URL}/${path}`;
		let body = JSON.stringify(params);

		if (this._logRequest)
			console.log(this._tag, `<- ${url}`);

		let requestInit: globalThis.RequestInit = {
			method: method,
			credentials: 'include',
			headers: HEADERS,
		}

		if (method !== "GET")
			requestInit["body"] = body;

		let response = await fetch(url, requestInit);

		let json = await response.json();

		if (json.error)
		{
			console.error(this._tag, `-> ${json.error}`);
			throw new Error(json.error);
		}

		if (this._logResponse)
			console.log(this._tag, "->", json);

		return json;
	}
}
