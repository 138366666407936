import React from 'react';
import { Admin, Resource } from 'react-admin';
import { Container } from "react-bootstrap";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Product, ProductCreate, ProductEdit } from "./admin/product";
import { Collection, CollectionCreate, CollectionEdit } from "./admin/collection";
import { Spin } from "./components/Spin";
import { dataProvider } from "./dataProvider";
import { authProvider } from "./authProvider";
import './stylesheet/App.scss';
import { Collections } from "./components/Collections";
import { Collection1 } from "./components/Collection";
import { Product1 } from "./components/Product";


import ItemIcon from '@material-ui/icons/Book';

function App()
{
	return (
		<BrowserRouter>
			<Switch>
				<Route exact path='/admin'>
					<AdminContent/>
				</Route>

				<Route exact path='/spin/*'>
					<SpinContent/>
				</Route>

				<Route exact path='/*'>
					<Container>
						<RootContent/>
					</Container>
				</Route>
			</Switch>
		</BrowserRouter>
	);
}

function RootContent()
{
	return (
		<Switch>
			<Route exact path='/' component={Collections}/>
			<Route path='/collection/:number' component={Collection1}/>
			<Route path='/product/:number' component={Product1}/>
		</Switch>
	)
}

function SpinContent()
{
	return (
		<switch>
			<Route exact path='/spin/:number.spin' component={Spin}/>
		</switch>
	)
}

function AdminContent()
{
	return (
		<Admin dataProvider={dataProvider} authProvider={authProvider}>
			<Resource name="collections" create={CollectionCreate} edit={CollectionEdit} list={Collection}
			          icon={ItemIcon}/>
			<Resource name="products" create={ProductCreate} edit={ProductEdit} list={Product} icon={ItemIcon}/>
		</Admin>
	)
}

export default App;
