import React from "react";
import { Card, Tab, Tabs } from "react-bootstrap";
import { IProduct } from "../../../common/interfaces/records";
import { backend } from "../index";
import { Clipbord } from "./Clipbord"
import { SpinImages } from "./SpinImage";


export class Product1 extends React.Component<{match: {params: {number: string;};};}, {product: IProduct}>
{
	constructor(props: any)
	{
		super(props);
		this.state = {product: {id: '', name: '', pictures: []}};
		this.loadData().catch();
	}

	render()
	{
		return (
			<>
				<Card>
					<div className={"d-flex justify-content-center"}>
						{typeof this.state.product.pictures !== 'undefined' ?
							<SpinImages pictures={this.state.product.pictures}/> : ''}
					</div>
					<Card.Body>
						<Tabs defaultActiveKey="Embed" id="uncontrolled-tab-example">
							<Tab eventKey="Embed" title="Embed">
								<div className={"mt-5"}>
									<Clipbord product={this.props.match.params.number}/>
								</div>
							</Tab>
							<Tab eventKey="Share" title="Share">
								<div className={"mt-5"}>
									<p>Coming soon</p>
								</div>
							</Tab>
							<Tab eventKey="GIF" title="GIF">
								<div className={"mt-5"}>
									<p>Coming soon</p>
								</div>
							</Tab>
						</Tabs>

					</Card.Body>
				</Card>


			</>
		);
	}

	async loadData()
	{
		let product = await backend.getProduct(this.props.match.params.number)
		this.setState(_ => ({
			product: product,
			text: ''
		}));
	}
}