import React from "react";
import { Card } from "react-bootstrap";
import { IPictures } from "../../../common/interfaces/records";
import Rotation from 'react-rotation'

export class SpinImages extends React.Component<{pictures: IPictures[]}>
{
	render()
	{
		return (
			<div className={'spin'}>
				<Rotation cycle={true} autoPlay={true} reverse={true}>
					{
						typeof this.props.pictures !== 'undefined' ?
							this.props.pictures.map((item) => (
								<Card.Img variant="top" src={`${item.src}`}/>
							)) : ''
					}
				</Rotation>
			</div>
		);
	}
}