import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BackendService } from "./services/BackendService";
import * as serviceWorker from './serviceWorker';

export const backend = new BackendService();

ReactDOM.render(
	<React.StrictMode>
		<App/>
	</React.StrictMode>,
	document.getElementById('root')
);

function init()
{
	backend.getConf().catch(e => console.log(e));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

init();
