import React from "react";
import { Link } from "react-router-dom";
import { ICollections } from "../../../common/interfaces/records";
import { backend } from "../index";
import { Container, Row, Col, Table } from 'react-bootstrap';

export class Collections extends React.Component<{}, {collections: ICollections[]}>
{
	constructor(props: any)
	{
		super(props);
		this.state = {collections: []};
		this.loadData().catch();
	}

	render()
	{
		return (
			<Container>
				<Row>
					<h2>Section</h2>
				</Row>
				<Row>
					<Col>
						<Content collections={this.state.collections}/>
					</Col>
				</Row>
			</Container>
		);
	}

	async loadData()
	{
		let collections = await backend.getCollections()
		this.setState(_ => ({
			collections: collections
		}));
	}
}

class Content extends React.Component<{collections: ICollections[]}>
{
	render()
	{
		return (
			<Table striped bordered hover>
				<thead>
				<tr>
					<th>Section name</th>
					<th>Other info</th>
					<th>Other info</th>
				</tr>
				</thead>
				<tbody>
				{this.props.collections.map(item => (
					<tr>
						<td key={item.id}>
							<Link to={`/collection/${item.id}`}>{item.name}</Link>
						</td>
					</tr>
				))}
				</tbody>
			</Table>
		);
	}
}
