import React from "react";
import Button from "react-bootstrap/Button";
import { CopyToClipboard } from 'react-copy-to-clipboard';

export class Clipbord extends React.Component<{product: string}>
{
	state = {
		link: 'https://spin.sp-service.com.ua/spin/',
		copied: false,
	};

	render()
	{
		return (
			<>
				<pre><code>
							&lt;iframe src={this.state.link}{this.props.product}.spin width="100%" height="100%"
					        frameBorder="0"&gt; &lt;/iframe&gt;
						</code></pre>
				<CopyToClipboard
					text={`<iframe src=${this.state.link}${this.props.product}.spin width="100%" height="100%" frameBorder="0"/> </iframe>`}
					onCopy={() => this.setState({copied: true})}>
					<Button>Copy</Button>
				</CopyToClipboard>
				{this.state.copied ? <span style={{color: 'green'}}>Copied.</span> : null}
			</>
		);
	}
}