import React from "react";
import { IProduct } from "../../../common/interfaces/records";
import { backend } from "../index";
import { SpinImages } from "./SpinImage"

export class Spin extends React.Component<{match: {params: {number: string;};};}, {product: IProduct}>
{
	constructor(props: any)
	{
		super(props);
		this.state = {product: {id: '', name: '', pictures: []}};
		this.loadData().catch();
	}

	render()
	{
		return (
			<>
				{typeof this.state.product.pictures !== 'undefined' ?
					<SpinImages pictures={this.state.product.pictures}/> : ''}
			</>
		);
	}

	async loadData()
	{
		let product = await backend.getSpin(this.props.match.params.number)
		this.setState(_ => ({
			product: product,
			text: '',
		}));
	}
}