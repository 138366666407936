import simpleRestProvider from 'ra-data-simple-rest';
import { UpdateParams, UpdateResult } from 'ra-core';
import { fetchUtils } from 'react-admin';

const fetchJson = (url: string, options: any = {credentials: 'include'}) => {
	if (!options.headers) {
		options.headers = new Headers({Accept: 'application/json', 'Content-Type': 'application/json'});
	}
	// add your own headers here
	//options.headers.set('X-Custom-Header', 'foobar');
	return fetchUtils.fetchJson(url, options);
}

const baseDataProvider = simpleRestProvider(`${process.env.REACT_APP_API_URL}/admin`, fetchJson);

export const dataProvider = {
	...baseDataProvider,

	update: (resource: string, params: UpdateParams): Promise<UpdateResult> => {
		if (resource !== 'products' || !params.data.pictures) {
			// fallback to the default implementation
			return baseDataProvider.update(resource, params);
		}
		/**
		 * For posts update only, convert uploaded image in base 64 and attach it to
		 * the `picture` sent property, with `src` and `title` attributes.
		 */

			// Freshly dropped pictures are File objects and must be converted to base64 strings
		const newPictures = params.data.pictures.filter(
			(p: any) => p.rawFile instanceof File
			);

		const formerPictures = params.data.pictures.filter(
			(p: any) => !(p.rawFile instanceof File)
		);

		return Promise.all(newPictures.map(convertFileToBase64))
			.then(base64Pictures =>
				base64Pictures.map(picture64 => ({
					src: picture64,
					title: `${params.data.title}`,
				}))
			)
			.then(transformedNewPictures =>
				baseDataProvider.update(resource, {
					...params,
					data: {
						...params.data,
						pictures: [
							...transformedNewPictures,
							...formerPictures,
						],
					},
				})
			);
	},

	create: (resource: string, params: any): any => {
		if (resource !== 'products' || !params.data.pictures) {
			// fallback to the default implementation
			return baseDataProvider.create(resource, params);
		}
		/**
		 * For posts update only, convert uploaded image in base 64 and attach it to
		 * the `picture` sent property, with `src` and `title` attributes.
		 */

			// Freshly dropped pictures are File objects and must be converted to base64 strings
		const newPictures = params.data.pictures.filter(
			(p: any) => p.rawFile instanceof File
			);
		const formerPictures = params.data.pictures.filter(
			(p: any) => !(p.rawFile instanceof File)
		);

		return Promise.all(newPictures.map(convertFileToBase64))
			.then(base64Pictures =>
				base64Pictures.map(picture64 => ({
					src: picture64,
					title: `${params.data.title}`,
				}))
			)
			.then(transformedNewPictures =>
				baseDataProvider.create(resource, {
					...params,
					data: {
						...params.data,
						pictures: [
							...transformedNewPictures,
							...formerPictures,
						],
					},
				})
			);
	},

};

const convertFileToBase64 = (file: any) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = () => resolve(reader.result);
		reader.onerror = reject;

		reader.readAsDataURL(file.rawFile);
	});
